<template>
  <div class="header" :class="{ 'center': isAuth, 'open': isOpen }">
    <div class="header__back" v-show="!isCourier && !isOrder && !isAuth">
    </div>
    <router-link v-if="isOrder" to="orders" class="header__back">
      <img src="@/assets/img/arrow.png" alt="">
    </router-link>
    <router-link v-if="isCourier" to="couriers" class="header__back">
      <img src="@/assets/img/arrow.png" alt="">
    </router-link>
    <p class="header__info" v-if="title">{{ title }}</p>
    <p class="header__info" v-if="order">Заказ №<b>{{ order | number }}</b> от {{ date | date }}</p>
    <div v-if="!isAuth" @click="menuHandler" class="header__burger">
      <img src="@/assets/img/menu.png" alt="">
    </div>   
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
			type: String
    },
    order: {
			type: String
    },
    date: {
			type: String
    },
    isAuth: {
      type: Boolean
    },
    isOrder: {
      type: Boolean
    },
    isCourier: {
      type: Boolean
    }
  },
  computed: {
    isOpen () {
      return this.$store.state.menuOpened
    }
  },
  methods: {
    menuHandler () {
      let val = !this.isOpen
      this.$store.dispatch('SET_MENU_STATE', val)
    }
  },
  filters: {
    date: value => {
      return !value ? '' : value.split('T')[0]
    },
    number: value => {
      return !value ? '' : value.split('-')[1]
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-color: $main;
  height: 50px;
  color: #000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  z-index: 1;
  position: relative;
  &.center {
    justify-content: center;
  }
  &__info {
    font-size: 18px;
    transition: opacity $menu-open ease-out 0s;
  }
  &__back {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      transition: opacity $menu-open ease-out 0s;
    }
  }
  &__burger { 
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color $menu-open ease-out 0s;
  }
  &.open {
    .header__burger {
      background-color: #f1bb00;
    }
    .header__info, .header__back {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>