<template>
	<div class="popup" :class="{ isOpened: isOpened }">
		<div class="popup__wrapper" @mousedown.self="proxyIsOpened = false">
			<div class="popup__inner">
				<div class="popup__close" @click.prevent="proxyIsOpened = false">
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="2.51415" height="20.1132" rx="0.5" transform="matrix(0.707105 -0.707108 0.707105 0.707108 0.000335693 1.77777)" fill="white"/>
						<rect width="2.51415" height="20.1132" rx="0.5" transform="matrix(-0.707105 -0.707108 -0.707105 0.707108 16 1.77777)" fill="white"/>
					</svg>
				</div>

				<h2 class="popup__title">
					{{ title }}
				</h2>

				<div class="popup__text">
					{{ text }}
				</div>

				<div v-if="question" class="popup__buttons">
					<button class="popup__button popup__button--green" @click.prevent="handleSubmitQuestion">
						{{ question }}
					</button>
					<button class="popup__button" @click.prevent="proxyIsOpened = false">
						Отменить
					</button>
				</div>
				
				<button v-else class="popup__button" @click.prevent="proxyIsOpened = false">
					Ок
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import popup from '../mixins/popup'

export default {
	name: 'MsgPopup',

	props: {
		isOpened: {
			type: Boolean,
			required: true
		},
		title: {
			type: String
		},
		text: {
			required: true
		},
		question: {
			type: String
		},
	},

	mixins: [popup],

	computed: {
		proxyIsOpened: {
			get () {
				return this.isOpened
			},
			set () {
				this.$emit('close-popup')
			}
		}
	},

	methods: {
		handleSubmitQuestion() {
			this.proxyIsOpened = false
			this.$emit('handle-question')
		}
	}
}
</script>

<style lang="scss">
.popup {
	&__inner {
		width: 100%;
		max-width: 380px;
		padding: 20px 20px 32px;
		background-color: #fff;
		color: #000;
		overflow: visible;
	}
	&__title {
		font-size: 22px;
		line-height: 26px;
		font-weight: 500;
		margin-bottom: 16px;
		text-align: center;
	}
	&__text {
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		padding: 10px 0;
	}
	&__button {
		display: block;
		width: 100%;
		background-color: $red;
		color: #fff;
		margin-top: 16px;
		padding: 12px;
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		transition: background-color 0.25s ease-out 0s;
		&:hover {
			background-color: lighten($red, 15%);
			color: #fff;
		}

		&--green {
			background-color: $green;

			&:hover {
				background-color: lighten($green, 15%);
				color: #fff;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;

		.popup__button {
			width: calc(50% - 8px);
		}
	}
}
.popup {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	color: #000;
	background: rgba(0, 0, 0, 0.3);
	opacity: 0;
	z-index: 1000;
	transition: 0.3s ease-out;
	&.isOpened {
		visibility: visible;
		opacity: 1;
		.popup__inner {
			transform: translateX(0) scale(1);
			opacity: 1;
		}
	}
	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
	}
	&__inner {
		position: relative;
		box-shadow: 0px 8px 20px #6F6F6F;
		margin: 16px;
		opacity: 0;
		border-radius: 5px;
		transform: translateY(20%) scale(0.8);
		overflow: hidden;
		transition: 0.3s ease-out;
	}
	&__close {
		position: absolute;
		display: block;
		top: 16px;
		right: 16px;
		z-index: 1;
		transition: 0.3s ease-out;
		svg {
			rect {
				fill: #b7b7b7;
				transition: 0.3s ease-out;
			}
		}
	}
}
</style>