<template>
  <div class="orders">
    <Header title="Мои заказы" />

    <div class="wrapper">
      <div class="salary__person">
        <div class="container">
          <div class="salary__inner">
            <p class="salary__name">{{ courier }}</p>
            <img src="@/assets/img/refresh.png" alt="" class="salary__img" @click="refresh">
          </div>
        </div>      
      </div>
      <div class="order__detail" v-if="!isLoading">
        <div class="container">
          <ul>
            <li class="order__detail-item">
              <span class="order__detail-gray">Номер<br>Время</span>
              <span class="order__detail-gray order__detail-right"><span class="order__detail-right-header"><span>Вид оплаты</span> <span>Сумма</span></span>Адрес</span>
            </li>
            <router-link tag="li" to="order" class="order__detail-item" v-for="(order, orderIndex) in orders" :key="orderIndex" :class="{ 'order__detail-item--success': order.ЗаказВыдан }" @click.native="setOrderLink(order.Ссылка)">
              <div class="order__detail-left">
                <p class="order__detail-header">{{ order.Номер | number }}</p> 
                к {{ order.ВремяДоставки | time }}
              </div>
              <div class="order__detail-right order__detail-count">
                <div class="order__detail-right-inner">
                  <p class="order__detail-header"><span>{{ order.ВидОплаты }}</span> <span>{{ order.Сумма }}</span></p>  
                  {{ order.АдресДоставки }}
                </div>
                <img src="@/assets/img/arrow-right.png" alt="" />
              </div>
            </router-link> 
          </ul>
          <span
            class="order__all"
            v-if="showSuccessOrders"
            @click="showAllOrders"
          >
            Все заказы
          </span> 
        </div>  
      </div>
      <Loader v-else />
    </div>

		<popup 
      :is-opened="openPopup"
      @close-popup="openPopup = false"
      :title="popupTitle"
      :text="popupText"
      :question="question" 
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Popup from '@/components/Popup.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'Orders',
  components: {
    Header,
    Popup,
    Loader
  },
  data () {
    return {
      openPopup: false,
			popupTitle: '',
			popupText: '',
      question: '',
      isLoading: false
    }
  },
  computed: {
    showSuccessOrders () {
      return this.$store.state.showSuccessOrders
    },
    orders () {
      let filtered = this.$store.state.orders
        .filter(order => {
          return !this.showSuccessOrders ? true : order.ЗаказВыдан
        })

      return filtered
    },
    courier () {
      return this.$store.state.courier
    }
  },
  created () {
    this.refresh()
    this.$root.$on('popup', this.popupHandler)
  },
  methods: {
    popupHandler ({title,text, question}) {
			this.popupTitle = title
      this.popupText = text
      this.question = question
			this.openPopup = true
		},
    refresh () {
      this.isLoading = true
      this.$store.dispatch('CHECK_AUTH', localStorage.getItem('pin'))
        .then(
          (response) => {
            this.isLoading = false
            if (response) {
              this.$store.dispatch('GET_ORDERS')
            } else if (response.status === 400) {
              this.$root.$emit('popup', {title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }		
          },
          (err) => {
            this.isLoading = false
            throw new Error(err)
          }
        )
        .catch (err => {
          this.isLoading = false
          this.$root.$emit('popup', {title: 'Ошибка', text: 'Ошибка загрузки'})
          // eslint-disable-next-line
          console.log(err);
        })   
    },
    setOrderLink(link) {
      this.$store.dispatch('SET_ORDER_ID', link)
      this.$store.dispatch('GET_ORDER', link)
    },
    showAllOrders () {
      this.$store.dispatch('SHOW_SUCCESS_ORDERS', false)
    }
  },
  filters: {
    time: value => {
      return !value ? '' : value.split('T')[1].slice(0, -3)
    },
    number: value => {
      return !value ? '' : value.split('-')[1]
    }
  }
}
</script>

<style lang="scss" scoped>
.orders {
  background-color: #f2f2f2;
  min-height: 100vh;
}
.wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.salary {
  min-height: 100vh;
  background-color: #f2f2f2;
  &__person {
    background-color: #fff;
    padding: 16px 0px;
  }
  &__name {
    font-size: 18px;
    font-weight: 500;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  &__img {
    cursor: pointer;
  }
}
.order {
  &__detail {
    background-color: #f2f2f2;
    font-size: 14px;
    padding-bottom: 50px;
    &-item {
      padding: 12px 10px 10px;
      border-bottom: 1px solid #cacaca;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
      &--success {
        background-color: #cfe9d3;  
      } 
    }
    &-header {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-right {
      width: calc(100% - 70px);
      margin-left: 10px;
      &-inner {
        margin-right: 10px;
        width: 100%;
      }
      br {
        margin-top: 8px;
      }
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 16px;
      }
    }
    &-left {
      width: 50px;
      margin-right: 10px;
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-gray {
      color: #7e7e7e;
    }
    &-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 10px;
      border-top: 1px solid #000;   
      position: relative;
      top: -1px;   
    }
    &-sum {
      width: calc(100% - 86px);
      margin-right: 26px;
    }
  }
  &__all {
    color: #000;
    font-size: 16px;
    cursor: pointer;
    margin: 32px auto 0;
    display: block;
    background-color: $main;
    border-radius: 50px;
    padding: 6px 20px 9px;
    width: 120px;
  }
}
</style>
