export default {
	methods: {
		closePopupEsc(e) {
			var code = e.keyCode || e.which
			if (code == 27 && !this.isModal) {
				this.proxyIsOpened = false
			}
		}
	},

	created() {
		document.addEventListener('keyup', this.closePopupEsc.bind(this))
	},

	beforeDestroy() {
		document.removeEventListener('keyup', this.closePopupEsc)
	}
}